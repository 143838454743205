import { Link, NavLink } from '@remix-run/react'
import { COPYRIGHT } from '~/config'
import { Wordmark } from '~/components'
import { LinkedInIcon, FormerlyKnownAsTwitterIcon } from '../icons'
import { CallToAction } from '~/utils/a-b'
import { P } from '../text/paragraph'

export const Footer = () => (
  <footer className="app">
    <aside>
      <NavLink to="/">
        <Wordmark />
      </NavLink>
    </aside>

    <nav>
      <ul className="footer-links">
        <li>
          <h4>Product</h4>
          <NavLink to="/platform">Platform</NavLink>
          <NavLink to="/business-intelligence">Business Intelligence</NavLink>
          <NavLink to="/embedded-analytics">Embedded Analytics</NavLink>
          <NavLink to="/integrations">Integrations</NavLink>
          <NavLink to="/calculations">Calculations</NavLink>
          <NavLink to="/data-modeling">Data Modeling</NavLink>
          <NavLink to="/data-input">Data Input</NavLink>
        </li>
        <li>
          <h4>Resources</h4>
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/customer-case-studies">Case Studies</NavLink>
          <NavLink to="/compare">Compare Omni</NavLink>
          <NavLink to="/customer-support">Customer Support</NavLink>
          <NavLink to="/security">Security</NavLink>
          <NavLink to="/snowflake">Omni + Snowflake</NavLink>
        </li>
        <li>
          <h4>Learn</h4>
          <a href="https://docs.omni.co">Documentation</a>
          <a href="https://docs.omni.co/guides">User Guides</a>
          <NavLink to="/changelog">Changelog</NavLink>
          <NavLink to="/demos">Demos</NavLink>
          <a href=" https://community.omni.co/">Community</a>
        </li>
        <li>
          <h4>Company</h4>
          <NavLink to="/about">About</NavLink>
          <a href="https://exploreomni.rippling-ats.com/">Careers</a>
          <a href="https://status.omniapp.co/">Status</a>
        </li>
        <li>
          <h4>Legal</h4>

          <NavLink to="/privacy">Privacy</NavLink>
          <NavLink to="/terms">Terms</NavLink>
        </li>
        <li className="footer-actions">
          <Link className="call-to-action size-md" to="/request-demo">
            Request Demo
          </Link>
          <CallToAction className="size-md" />
        </li>
      </ul>
    </nav>

    <div className="copy-and-social">
      <P fontSize="xs" color="text2">
        {COPYRIGHT}
      </P>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/company/exploreomni/"
            rel="noreferrer noopener"
            target="_blank"
            aria-label="Omni on LinkedIn"
          >
            <LinkedInIcon />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/Omni"
            rel="noreferrer noopener"
            target="_blank"
            aria-label="Omni on X"
          >
            <FormerlyKnownAsTwitterIcon />
          </a>
        </li>
      </ul>
    </div>
  </footer>
)
