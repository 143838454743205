export const FormerlyKnownAsTwitterIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2352_17182)">
      <path
        d="M12.0811 8.46429L19.3627 0H17.6372L11.3146 7.34942L6.2648 0H0.44043L8.07674 11.1136L0.44043 19.9897H2.16602L8.84282 12.2285L14.1758 19.9897H20.0002L12.0807 8.46429H12.0811ZM9.71769 11.2115L8.94397 10.1049L2.78778 1.29901H5.43819L10.4063 8.40562L11.18 9.51229L17.638 18.7498H14.9876L9.71769 11.212V11.2115Z"
        fill="#060609"
      />
    </g>
    <defs>
      <clipPath id="clip0_2352_17182">
        <rect
          width="19.5597"
          height="20"
          fill="white"
          transform="translate(0.44043)"
        />
      </clipPath>
    </defs>
  </svg>
)
