export const LinkedInIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2352_17180)">
      <path
        d="M22.7261 0C23.1904 0 23.5833 0.178572 23.9048 0.535715C24.2619 0.857143 24.4404 1.25 24.4404 1.71428V22.2857C24.4404 22.75 24.2619 23.1428 23.9048 23.4643C23.5833 23.8214 23.1904 24 22.7261 24H2.15471C1.69043 24 1.27972 23.8214 0.922573 23.4643C0.601145 23.1428 0.44043 22.75 0.44043 22.2857V1.71428C0.44043 1.25 0.601145 0.857143 0.922573 0.535715C1.27972 0.178572 1.69043 0 2.15471 0H22.7261ZM7.67258 20.5715H7.72615V9.10715H4.13686V20.5715H7.67258ZM4.45828 6.96428C4.85115 7.35714 5.33329 7.55357 5.90471 7.55357C6.47614 7.55357 6.95829 7.35714 7.35115 6.96428C7.77971 6.53572 7.994 6.05357 7.994 5.51785C7.994 4.94642 7.77971 4.46429 7.35115 4.07143C6.95829 3.64285 6.47614 3.42857 5.90471 3.42857C5.33329 3.42857 4.85115 3.64285 4.45828 4.07143C4.06543 4.46429 3.869 4.94642 3.869 5.51785C3.869 6.05357 4.06543 6.53572 4.45828 6.96428ZM21.0119 20.5715V14.3035C21.0119 13.4465 20.9583 12.7142 20.8511 12.1072C20.744 11.5 20.5476 10.9464 20.2619 10.4464C19.9762 9.91072 19.5297 9.51786 18.9226 9.26785C18.3512 8.98214 17.6369 8.83928 16.7798 8.83928C15.9582 8.83928 15.244 9.01786 14.6369 9.375C14.0655 9.73214 13.6547 10.1607 13.4048 10.6607H13.3511V9.10715H9.92258V20.5715H13.5119V14.8928C13.5119 14 13.6547 13.2857 13.9404 12.75C14.2262 12.2143 14.7797 11.9464 15.6011 11.9464C16.0297 11.9464 16.3869 12.0358 16.6726 12.2143C16.9583 12.3929 17.1369 12.6607 17.2083 13.0178C17.3154 13.375 17.3868 13.6964 17.4226 13.9822C17.4582 14.2321 17.4761 14.5715 17.4761 15V20.5715H21.0119Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2352_17180">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.44043)"
        />
      </clipPath>
    </defs>
  </svg>
)
